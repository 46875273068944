import { searchEntities } from 'api/entities';
import { mapEntityParams, ROOT_HIERARCHY_ID } from 'constants/entities';
import { IMediaFile, ISelectOption } from 'interfaces';
import {
  EntitiesMap,
  EntityCountersMap,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';

import {
  arrayIntersection,
  convertEntityWithRelationsToSelectOptions,
  replaceOrAppendArrayValue,
} from 'utils';

import {
  Entity,
  EntityParameter,
  EntityParametersIdTitleMap,
  GeometryParameter,
  Hierarchy,
  MediaParameter,
  TextParameter,
} from '../types/entities';

import { getDefaultMediaFile } from './media';

export interface PossibleValuesMap {
  [key: number]: string[];
}

const getMapEntityPath = (
  entitiesMap: EntitiesMap,
  entityId: number
): number[] => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];

  if (!entity || !parentId) {
    return [ROOT_HIERARCHY_ID];
  }

  return [...getMapEntityPath(entitiesMap, parentId), parentId];
};

const getMapEntityFullPath = (entitiesMap: EntitiesMap, entityId: number) => [
  ...getMapEntityPath(entitiesMap, entityId),
  entityId,
];

const moveOldParentCounters = (
  id: number,
  count: number,
  movingCount: number,
  newParentPath: number[],
  isParentsRelated: boolean
) => {
  // as recursion goes from bottom to top need limit unrelated path ids change
  const movingDiff = newParentPath.includes(id) ? count : count - movingCount;

  return isParentsRelated ? movingDiff : count - movingCount;
};

const moveNewParentCounters = (
  id: number,
  count: number,
  movingCount: number,
  oldParentPath: number[],
  isParentsRelated: boolean
) => {
  // as recursion goes from bottom to top need limit unrelated path ids change
  const movingDiff = oldParentPath.includes(id) ? count : count + movingCount;

  return isParentsRelated ? movingDiff : count + movingCount;
};

export const extractParamPossibleValues = (
  entityRelatedParameters: Record<number, EntityParameter[]>
): PossibleValuesMap => {
  const possibleValuesMap: PossibleValuesMap = {};

  for (const key in entityRelatedParameters) {
    const paramsArray = entityRelatedParameters[key];

    if (paramsArray) {
      paramsArray.forEach((param) => {
        possibleValuesMap[param.id] = (param.settings.allowedValues || []).map(
          (value) => String(value)
        );
      });
    }
  }
  return possibleValuesMap;
};

// TODO: same as getParamIdByTitle from utils/map.tsx
export const getEntityParameterIdByTitle = (
  parameters: EntityParameter[],
  title: string
) => String(parameters.find((parameter) => parameter.title === title)?.id ?? 0);

// TODO: looks the same as getMapObjectParamIdMap from utils/map?
export const getEntityParametersIdTitleMap = (
  parameters: EntityParameter[],
  parameterTitles: string[],
  mode: 'straight' | 'reverse' = 'straight'
) =>
  parameterTitles.reduce((acc, curr) => {
    const id = getEntityParameterIdByTitle(parameters, curr);

    if (mode === 'straight') {
      acc[id] = curr;
    }

    if (mode === 'reverse') {
      acc[curr] = id;
    }

    return acc;
  }, {} as EntityParametersIdTitleMap);

export const getEntityParameterValue = <T,>(
  entity: Entity | null | undefined,
  parametersIdTitleMap: EntityParametersIdTitleMap,
  parameterTitle: string
): T | null =>
  parametersIdTitleMap
    ? entity?.parameters[parametersIdTitleMap[parameterTitle]]?.value
    : null;

export const findHierarchyNode = (hierarchy: Hierarchy, id: number) => {
  let result: Hierarchy | null = null;

  if (hierarchy.id === id) {
    return hierarchy;
  }

  if (hierarchy.children) {
    hierarchy.children.some((node) => (result = findHierarchyNode(node, id)));
  }

  return result;
};

export const processHierarchyNodeMoving = (
  hierarchy: Hierarchy,
  node: Hierarchy,
  oldParentId: number,
  newParentId: number,
  processedParentIds: number[]
) => {
  let processedHierarchy = { ...hierarchy };

  if (processedHierarchy.id === oldParentId) {
    processedHierarchy = {
      ...processedHierarchy,
      children: processedHierarchy.children.filter(
        (hierarchy) => hierarchy.id !== node.id
      ),
    };
    processedParentIds.push(oldParentId);
  }

  if (processedHierarchy.id === newParentId) {
    processedHierarchy = {
      ...processedHierarchy,
      children: [...processedHierarchy.children, node],
    };
    processedParentIds.push(newParentId);
  }

  if (processedParentIds.length === 2) {
    return processedHierarchy;
  }

  for (let i = 0; i < processedHierarchy.children.length; i++) {
    processedHierarchy.children[i] = processHierarchyNodeMoving(
      processedHierarchy.children[i],
      node,
      oldParentId,
      newParentId,
      processedParentIds
    );
  }

  return processedHierarchy;
};

export const createHierarchyNode = (
  id: number,
  children?: Hierarchy[]
): Hierarchy => ({ id, children: children ?? [] });

export const processHierarchy = (
  hierarchy: Hierarchy,
  id: number,
  processFunc: (node: Hierarchy) => Hierarchy
) => {
  if (hierarchy.id === id) {
    return processFunc(hierarchy);
  }

  for (let i = 0; i < hierarchy.children.length; i++) {
    hierarchy.children[i] = processHierarchy(
      hierarchy.children[i],
      id,
      processFunc
    );
  }

  return hierarchy;
};

export const updateHierarchyProperty = <T extends keyof Hierarchy>(
  hierarchy: Hierarchy,
  id: number,
  property: T,
  value: Hierarchy[T]
) =>
  processHierarchy(hierarchy, id, (hierarchy) => ({
    ...hierarchy,
    [property]: value,
  }));

export const deleteNodeFromHierarchy = (
  hierarchy: Hierarchy,
  id: number,
  parentID: number
) =>
  processHierarchy(hierarchy, parentID, (hierarchy) => ({
    ...hierarchy,
    children: hierarchy.children.filter((i) => i.id != id),
  }));

export const moveHierarchyNode = (
  hierarchy: Hierarchy,
  nodeId: number,
  oldParentId: number,
  newParentId: number
) => {
  const node = findHierarchyNode(hierarchy, nodeId);
  const processedParentIds: number[] = [];

  if (oldParentId === newParentId || !node) {
    return hierarchy;
  }

  return processHierarchyNodeMoving(
    hierarchy,
    node,
    oldParentId,
    newParentId,
    processedParentIds
  );
};

export const processEntityCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  parentId: number,
  processFunc: (id: number, count: number) => number
): EntityCountersMap => {
  const grandParentId = entitiesMap[parentId]?.parentIDs?.[0];
  const count = countersMap[parentId];

  return {
    [parentId]: processFunc(parentId, count),
    ...(grandParentId &&
      processEntityCounters(
        entitiesMap,
        countersMap,
        grandParentId,
        processFunc
      )),
  };
};

export const increaseEntityObjectCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  layerTemplateId: number,
  objectTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];
  const shouldAddCounter =
    !countersMap[entityId] && entity?.entity?.templateID === layerTemplateId;
  const shouldIncreaseSingle =
    parentId && entity?.entity?.templateID === objectTemplateId;

  return {
    ...countersMap,
    ...(shouldAddCounter && { [entity?.entity?.id]: 0 }),
    ...(shouldIncreaseSingle &&
      processEntityCounters(
        entitiesMap,
        countersMap,
        parentId,
        (_id, value) => value + 1
      )),
  };
};

export const decreaseEntityObjectCounters = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  layerTemplateId: number,
  objectTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const parentId = entity?.parentIDs?.[0];
  const decreasedEntityId = String(entity?.entity?.id);
  const { [decreasedEntityId]: _removedCounter, ...restCounters } = countersMap;

  const shouldRemoveCounter =
    parentId && entity?.entity?.templateID === layerTemplateId;
  const shouldDecreaseSingle =
    parentId && entity?.entity?.templateID === objectTemplateId;
  const shouldDecreaseMultiple =
    parentId && entity?.entity?.templateID === layerTemplateId;

  const currentCountersMap = shouldRemoveCounter ? restCounters : countersMap;

  return {
    ...currentCountersMap,
    ...(shouldDecreaseSingle &&
      processEntityCounters(
        entitiesMap,
        currentCountersMap,
        parentId,
        (_id, value) => value - 1
      )),
    ...(shouldDecreaseMultiple &&
      processEntityCounters(
        entitiesMap,
        currentCountersMap,
        parentId,
        (_id, value) => value - countersMap[decreasedEntityId]
      )),
  };
};

export const moveMapEntityCounter = (
  entitiesMap: EntitiesMap,
  countersMap: EntityCountersMap,
  entityId: number,
  oldParentEntityId: number,
  newParentEntityId: number,
  layerTemplateId: number
) => {
  const entity = entitiesMap[entityId];
  const oldParentPath = getMapEntityFullPath(entitiesMap, oldParentEntityId);
  const newParentPath = getMapEntityFullPath(entitiesMap, newParentEntityId);
  const isParentsRelated =
    arrayIntersection(oldParentPath, newParentPath).length > 1;
  const movingCount =
    entity.entity.templateID === layerTemplateId ? countersMap[entityId] : 1;

  return {
    ...countersMap,
    ...processEntityCounters(
      entitiesMap,
      countersMap,
      oldParentEntityId,
      (_id, count) =>
        moveOldParentCounters(
          _id,
          count,
          movingCount,
          newParentPath,
          isParentsRelated
        )
    ),
    ...processEntityCounters(
      entitiesMap,
      countersMap,
      newParentEntityId,
      (id, count) =>
        moveNewParentCounters(
          id,
          count,
          movingCount,
          oldParentPath,
          isParentsRelated
        )
    ),
  };
};

export const getMapObjectEntityValues = (
  parametersMap: EntityParametersIdTitleMap,
  mapObjectEntity: Entity
) => {
  const name = mapObjectEntity.title;
  const type = getEntityParameterValue<TextParameter>(
    mapObjectEntity,
    parametersMap,
    mapEntityParams.TYPE
  );

  const status = getEntityParameterValue<TextParameter>(
    mapObjectEntity,
    parametersMap,
    mapEntityParams.STATUS
  );

  const geometry = getEntityParameterValue<GeometryParameter>(
    mapObjectEntity,
    parametersMap,
    mapEntityParams.GEOMETRY
  );

  const date = getEntityParameterValue<TextParameter>(
    mapObjectEntity,
    parametersMap,
    mapEntityParams.DATE
  );

  const description = getEntityParameterValue<TextParameter>(
    mapObjectEntity,
    parametersMap,
    mapEntityParams.DESCRIPTION
  );

  const media =
    getEntityParameterValue<MediaParameter>(
      mapObjectEntity,
      parametersMap,
      mapEntityParams.MEDIA
    )?.map?.((file: IMediaFile) => getDefaultMediaFile(file.url)) ?? null;

  return { name, type, status, geometry, date, description, media };
};

export const updateMapEntity = (
  entitiesMap: EntitiesMap,
  entity: Entity
): EntitiesMap => ({
  ...entitiesMap,
  [String(entity.id)]: {
    ...entitiesMap[String(entity.id)],
    entity,
  },
});

export const moveMapEntity = (
  entitiesMap: EntitiesMap,
  entityId: number,
  oldParentEntityId: number,
  newParentEntityId: number
) => {
  const entity = entitiesMap[entityId];
  const oldParentEntity = entitiesMap[oldParentEntityId];
  const newParentEntity = entitiesMap[newParentEntityId];

  return {
    ...entitiesMap,
    ...(oldParentEntity && {
      [oldParentEntityId]: {
        ...oldParentEntity,
        childIDs: oldParentEntity.childIDs.filter((id) => id !== entityId),
      },
    }),
    ...(entity && {
      [entityId]: {
        ...entity,
        parentIDs: replaceOrAppendArrayValue(
          entity.parentIDs,
          newParentEntityId,
          (id) => id === oldParentEntityId
        ),
      },
    }),
    ...(newParentEntity && {
      [newParentEntityId]: {
        ...newParentEntity,
        childIDs: [...newParentEntity.childIDs, entityId],
      },
    }),
  };
};

export const getInitialLayerEntity = (
  mapLayerTemplateID: number,
  parentId?: number
): Entity => ({
  id: 0,
  templateID: mapLayerTemplateID,
  title: '',
  parentEntityID: parentId,
  createdBy: {},
  parameters: {},
});

export const getSelectOptionsFromSearch = async (
  mapObjectTemplateId: number,
  optionsCallback: (options: ISelectOption[]) => void,
  filterTemplateIDs?: number[],
  selectOptionsExcludeIDs?: number[]
) => {
  await searchEntities({
    maxNestedEntityLevel: 9999,
    parentEntityIDs: [0],
    templateIDs: filterTemplateIDs || [],
  }).then((res) => {
    const options = convertEntityWithRelationsToSelectOptions(
      res.results,
      mapObjectTemplateId,
      selectOptionsExcludeIDs || []
    );
    optionsCallback(options);
  });
};

export const getSelectOptionsFromEnumParam = (
  param: EntityParameter,
  checkedValues?: string[]
): ISelectOption[] =>
  param.settings.allowedValues
    ? param.settings.allowedValues.map((v) => ({
        label: v,
        value: v,
        checked: !!checkedValues && checkedValues.includes(v),
      }))
    : [];
