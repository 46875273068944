import { FC, memo, useMemo } from 'react';
import { MAP_ENTITY_PARAM_VALUES } from 'constants/entities';
import { LAYERS_POLYGON } from 'constants/map';
import { ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL } from 'constants/modals';
import { useAppDispatch, useAppSelector } from 'hooks';
import { showLayersSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import {
  entitiesMapSelector,
  objectEntityParametersSelector,
  temporaryEntitiesSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { modalSelector } from 'store/slices/service/modalsSlice/selectors';
import { MapEntity, MapFeaturePoint } from 'types';

import { Label, Line, Polygon } from 'components/Map/FeatureTypes';
import { getFeaturesFromMapEntities } from 'utils';
import { getEntityParametersIdTitleMap } from 'utils/entity';

import { getLabelConfig, getPointLabelConfig } from './configs';
import { Points } from './Points';

export const LayersSource: FC = memo(() => {
  const showLayers = useAppSelector(showLayersSelector);
  const entities = useAppSelector(entitiesMapSelector);
  const objectParameters = useAppSelector(objectEntityParametersSelector);
  const temporaryEntities = useAppSelector(temporaryEntitiesSelector);
  const entityPreviewModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_PREVIEW_MODAL)
  );
  const entityDetailsModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_DETAILS_MODAL)
  );

  const dispatch = useAppDispatch();

  const activeEntity = (entityPreviewModal?.props ||
    entityDetailsModal?.props) as MapEntity | undefined;

  const parametersIdTitleMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        objectParameters,
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [objectParameters]
  );

  const { features, labels } = useMemo(
    () =>
      getFeaturesFromMapEntities(
        [...Object.values(entities), ...temporaryEntities],
        parametersIdTitleMap
      ),
    [entities, temporaryEntities, parametersIdTitleMap]
  );

  const handleModals = (
    entityId: string,
    openModalId: string,
    closeModal?: string | null,
    shouldOpen = true
  ) => {
    const targetEntity = entities[entityId || ''];

    closeModal && dispatch(modalsActions.removeModal(closeModal));

    shouldOpen &&
      targetEntity &&
      dispatch(
        modalsActions.addModal({
          id: openModalId,
          isOpen: true,
          props: targetEntity,
        })
      );
  };

  const handlePointClick = (point: MapFeaturePoint) =>
    handleModals(
      String(point.id),
      ENTITY_PREVIEW_MODAL,
      null,
      !entityDetailsModal?.isOpen
    );

  const handlePointDbClick = (point: MapFeaturePoint) =>
    handleModals(String(point.id), ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL);

  if (!showLayers) {
    return null;
  }

  return (
    <>
      <Polygon id={LAYERS_POLYGON} data={features.polygons} />
      <Line data={features.lines} />
      <Points
        points={features.points}
        activeEntity={activeEntity}
        onClick={handlePointClick}
        onDbClick={handlePointDbClick}
      />
      <Label
        id="layers-polygon-label"
        data={labels.polygons}
        config={getLabelConfig(activeEntity?.entity?.id)}
      />
      <Label
        id="layers-line-label"
        data={labels.lines}
        config={getLabelConfig(activeEntity?.entity?.id)}
      />
      <Label
        id="layers-point-label"
        data={labels.points}
        config={getPointLabelConfig(activeEntity?.entity?.id)}
      />
    </>
  );
});

LayersSource.displayName = 'LayersSource';
