export const ROOT_HIERARCHY_ID = 0;

export const MAP_ENTITY_HIERARCHY_NEST_LEVEL = 3;

export enum predefinedTemplates {
  MAP_LAYER = 'mapLayer',
  MAP_OBJECT = 'mapObject',
}

export enum mapEntityParams {
  GEOMETRY = 'Координаты',
  DATE = 'Дата',
  OPACITY = 'Прозрачность',
  DESCRIPTION = 'Описание',
  PLACE = 'Место',
  COLOR = 'Цвет',
  TYPE = 'Тип объекта',
  STATUS = 'Статус',
  MEDIA = 'Медиа',
}

export const MAP_ENTITY_PARAM_VALUES = Object.values(mapEntityParams);

export const MAP_ENTITY_FILTERABLE_PARAMS = [
  mapEntityParams.STATUS,
  mapEntityParams.DATE,
  mapEntityParams.TYPE,
];
