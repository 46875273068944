export enum errorMessages {
  GET_FILTERS_ERROR = 'Не удалось получить фильтры',
  SEARCH_POSTS_ERROR = 'Не удалось получить результаты поиска',
  ADD_FEATURE_ERROR = 'Не удалось создать объект',
  EDIT_FEATURE_ERROR = 'Не удалось изменить объект',
  DELETE_FEATURE_ERROR = 'Не удалось удалить объект',
  DELETE_MAP_ENTITIES_ERROR = 'Не удалось удалить объекты',
  GET_GEOSPOOF_ERROR = 'Не удалось получить данные',
  GET_LAYER_CHILDREN_ERROR = 'Не удалось получить список проектов',
  ADD_LAYER_ERROR = 'Не удалось создать проект',
  DELETE_LAYER_ERROR = 'Не удалось удалить проект',
  EDIT_LAYER_ERROR = 'Не удалось изменить проект',
  ADD_FEATURE_MEDIA_ERROR = 'Не удалось добавить медиафайлы',
  DELETE_FEATURE_MEDIA_ERROR = 'Не удалось удалить медиафайл',
  ADD_BATCH_FEATURES_ERROR = 'Не удалось сохранить объекты',
  GET_SEARCH_HISTORY_ERROR = 'Не удалось получить историю поиска',
  GET_SEARCH_INFO_ERROR = 'Не удалось получить результат поиска',
  GET_PICTURES_UPLOAD_ID_ERROR = 'Не удалось получить ссылку на загрузку медиафайлов',
  GET_ENTITY_PICTURES_ERROR = 'Не удалось загрузить медиафайлы',
  DOWNLOAD_FEATURES_ERROR = 'Не удалось загрузить объекты',
  ADD_REPORT_ERROR = 'Не удалось добавить новый отчет',
  ADD_CLIENT_ERROR = 'Не удалось добавить нового заказчика',
  GET_ATTRIBUTES_ERROR = 'Не удалось получить атрибуты',
  GET_PRIVILEGES_ERROR = 'Не удалось получить роль пользователя',
  GET_IMAGERY_ERROR = 'Не удалось получить снимок',
  UPDATE_IMAGERY_ERROR = 'Не удалось обновить снимок',
  GET_REPORTS_ERROR = 'Не удалось получить список отчетов',
  GET_GC_RANGES_ERROR = 'Не удалось получить данные из Geoconfirmed',
  GET_GC_FEATURES_ERROR = 'Нет данных по таким датам',
  GET_PARSERS_ERROR = 'Не удалось получить список аккаунтов, выполняющих парсинг',
  ADD_BOOKMARK_ERROR = 'Не удалось добавить в избранное',
  DELETE_BOOKMARK_ERROR = 'Не удалось удалить пост из избранного',
  GET_BOOKMARK_IDS_ERROR = 'Не удалось получить избранные посты',
  GET_MAP_V3_STATE_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_ENTITIES_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_FILTERED_ENTITIES_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_ENTITY_CHILDREN_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_ENTITY_COUNTERS_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_OBJECTS_ERROR = 'Не удалось получить состояние карты',
  GET_MAP_V2_STATE_ERROR = 'Не удалось получить сущности карты',
  TRANSLATION_ERROR = 'Не удалось выполнить перевод',
  BUILD_REPORT_ERROR = 'Не удалось получить отчет по объекту',
  ENTITY_CREATION_ERROR = 'Не удалось создать сущность',
  ENTITY_UPDATE_ERROR = 'Не удалось обновить сущность',
  ENTITY_DELETE_ERROR = 'Не удалось удалить сущность',
  ENTITY_ADD_MEDIA_ERROR = 'Не удалось добавить медиа к сущности',
  RELINK_ENTITY_ERROR = 'Не удалось изменить связи сущностей',
  GET_PREDEFINED_TEMPLATES_ERROR = 'Не удалось получить преодпределённые шаблоны сущностей',
  ENTITY_EXPORT_ERROR = 'Не удалось экспортировать сущность',
}

export enum successMessages {
  ENTITY_CREATION_SUCCESS = 'Сущность была успешно создана',
  ENTITY_UPDATE_SUCCESS = 'Сущность была успешно обновлена',
}

export enum convertValidationErrors {
  UNCORRECT_TO_XYH = 'Некорректный формат ввода (пример: 48.2394258, 37.4325399)',
  UNCORRECT_TO_WGS = 'Некорректный формат ввода (пример: 5346339, 7383685)',
  GET_RESULT_ERROR = 'Не удалось получить данные',
  UNCORRECT_COORDINATES = 'Неверное значение координат (широта, долгота)',
}

export enum infoMessages {
  ENTITY_EXPORT_STARTED = 'Экспорт в процессе... Не закрывайте страницу',
}
