import { createSlice } from '@reduxjs/toolkit';

import { notify } from 'utils';

import { TABS_REDUCER_NAMES } from '../constants';

import { getImageryThunk, updateImageryThunk } from './actions';
import {
  AddVisibleObjectsAction,
  ImageryState,
  RemoveVisibleObjectsAction,
  SetObjectsAction,
  SetShowPreviewAction,
  SetVisibleObjectsAction,
  UpdateVisibleObjectsAction,
} from './types';

const initialState: ImageryState = {
  objects: [],
  visibleObjects: [],
  showPreview: false,
};

const imagerySlice = createSlice({
  name: TABS_REDUCER_NAMES.IMAGERY,
  initialState: initialState,
  reducers: {
    setObjects(state, action: SetObjectsAction) {
      state.objects = action.payload;
    },
    setVisibleObjects(state, action: SetVisibleObjectsAction) {
      state.visibleObjects = action.payload;
    },
    setShowPreview(state, action: SetShowPreviewAction) {
      state.showPreview = action.payload;
    },
    addVisibleImageryObjects(state, action: AddVisibleObjectsAction) {
      state.visibleObjects = [...state.visibleObjects, action.payload];
    },
    removeVisibleImageryObjects(state, action: RemoveVisibleObjectsAction) {
      state.visibleObjects = [
        ...state.visibleObjects.filter(
          (value) => value.id !== action.payload.id
        ),
      ];
    },
    updateVisibleImageryObjects(state, action: UpdateVisibleObjectsAction) {
      state.visibleObjects = [
        ...state.visibleObjects.filter(
          (value) => value.id !== action.payload.id
        ),
        action.payload,
      ];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getImageryThunk.fulfilled, (state, action) => {
        state.objects = action.payload;
      })
      .addCase(getImageryThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(updateImageryThunk.fulfilled, (state, action) => {
        state.objects = state.objects.map((value) =>
          value.id === action.payload.id ? action.payload : value
        );
      }),
});

export const { actions: imageryActions, reducer: imageryReducer } =
  imagerySlice;
