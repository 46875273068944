import { createSlice } from '@reduxjs/toolkit';
import { initialMapTypesState } from 'constants/map';

import { MAP_REDUCER_NAMES } from '../constants';

import {
  SetCoordsModeAction,
  SetShowAimAction,
  SetShowCursorsAction,
  SetShowLabelsAction,
  SetShowLayersAction,
  SettingsState,
  SetTypesAction,
} from './types';

const initialState: SettingsState = {
  coordsMode: 'wgs',
  showLabels: true,
  showLayers: true,
  showAim: false,
  types: initialMapTypesState,
  showCustomCursors: true,
};

const settingsSlice = createSlice({
  name: MAP_REDUCER_NAMES.SETTINGS,
  initialState: initialState,
  reducers: {
    setCoordsMode(state, action: SetCoordsModeAction) {
      state.coordsMode = action.payload;
    },
    setShowLabels(state, action: SetShowLabelsAction) {
      state.showLabels = action.payload;
    },
    setShowAim(state, action: SetShowAimAction) {
      state.showAim = action.payload;
    },
    setShowLayers(state, action: SetShowLayersAction) {
      state.showLayers = action.payload;
    },
    setTypes(state, action: SetTypesAction) {
      state.types = action.payload;
    },
    setShowCursors(state, action: SetShowCursorsAction) {
      state.showCustomCursors = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: settingsActions, reducer: settingsReducer } =
  settingsSlice;
