import { Results } from '@mapbox/mapbox-gl-geocoder';
import {
  IBuildReportRequest,
  IDateRangeFilter,
  IImageryFilter,
  IImageryUploadRequest,
  ImageryUpdateRequest,
} from 'interfaces';
import axios from 'services/axios';

import { MAP_BOX_TOKEN } from '../constants/map';

import { handleAxiosResponse } from './apiUtils';

export const getAttributes = () => axios.get('/api/map/attributes');

export const getGCAvailableRanges = () => axios.get('/api/geoconfirmed');

export const getAllGCObjects = (dateRange: IDateRangeFilter) =>
  axios.get('/api/geoconfirmed/all', { params: dateRange });

export const getImagery = (params: IImageryFilter) =>
  axios.get('/api/imagery', { params });

export const updateImagery = ({ name, id }: ImageryUpdateRequest) =>
  axios.put(`/api/imagery/${id}`, { name: name });

export const uploadImagery = ({ formData, ...params }: IImageryUploadRequest) =>
  axios.post('/api/imagery', formData, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const deleteImagery = (imageId: number) =>
  axios.delete(`/api/imagery/${imageId}`);
export const getV3MapState = () => axios.get('/api/map/v3');

// todo: proper url is TBD
export const buildReport = (payload: IBuildReportRequest[]) =>
  axios.post('/api/buildReport', payload, { responseType: 'blob' });

export const reverseGeocodingRequest = (
  lng: number,
  lat: number,
  dataType: 'region' | 'place'
) =>
  handleAxiosResponse<Results>(
    axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
      {
        params: {
          limit: 1,
          access_token: MAP_BOX_TOKEN,
          types: dataType,
          language: 'ru',
        },
      }
    )
  );
