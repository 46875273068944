import { FC, memo, useState } from 'react';
import { MAP_ENTITY_HIERARCHY_NEST_LEVEL } from 'constants/entities';
import { ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL } from 'constants/modals';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppDispatch } from 'hooks';
import { useMapRef } from 'hooks/map';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  deleteEntityThunk,
  getMapEntityChildrenThunk,
  getMapEntityCountersThunk,
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  EntitiesMap,
  EntityCountersMap,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { EntityParametersIdTitleMap, PredefinedTemplate } from 'types/entities';
import { MapEntity } from 'types/map';
import useResizeObserver from 'use-resize-observer';

import { IContextMenuTooltipItem } from 'components/ContextMenuTooltip';
import { LayerDetailsProps } from 'components/Map/LayerDetails';
import { Tree, TTreeProps } from 'components/Tree';
import { Node } from 'components/Tree/Node';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import { calculateGeometryCenter, downloadObject, notify } from 'utils';
import { getMapObjectEntityValues } from 'utils/entity';

import { exportEntity } from '../../../../api/entities';
import { errorMessages, infoMessages } from '../../../../constants/errors';

import { renderNodeArrow } from './NodeArrow';
import { renderNodeCounter } from './NodeCounter';
import { renderNodeIcon } from './NodeIcon';
import { renderNodeName } from './NodeName';
import {
  EntityNode,
  EntityNodeData,
  EntityNodeInfo,
  EntityNodeMoveData,
  EntityNodeState,
} from './types';
import {
  getEntityChildrenWithUpdatedState,
  getNodeDropDisabled,
  NODE_INDENT,
} from './utils';

interface EntitiesTreeProps
  extends TTreeProps<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState> {
  data: EntityNodeData[];
  objectTemplate?: PredefinedTemplate;
  entitiesMap: EntitiesMap;
  entityCountersMap: EntityCountersMap;
  objectParametersMap: EntityParametersIdTitleMap;
  search: string;
  setLayerDetailsProps: (props: LayerDetailsProps | undefined) => void;
}

export const EntitiesTree: FC<EntitiesTreeProps> = memo(
  ({
    data,
    objectTemplate,
    entitiesMap,
    entityCountersMap,
    objectParametersMap,
    search,
    setLayerDetailsProps,
    ...props
  }) => {
    const { ref, height } = useResizeObserver();
    const { mapRef } = useMapRef();
    const [processedNode, setProcessedNode] = useState<EntityNode | null>(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const dispatch = useAppDispatch();

    const hasData = !!data.length;

    /* *** Common handlers *** */

    const handleDeleteModalClose = () => {
      setProcessedNode(null);
      setDeleteModalOpen(false);
    };

    const handleLayerModalClose = () => {
      setProcessedNode(null);
      setLayerDetailsProps(undefined);
    };

    const zoomToObject = (node: EntityNode) => {
      const { geometry } = getMapObjectEntityValues(
        objectParametersMap,
        node.data.entity.entity
      );

      if (!mapRef.current || !geometry) {
        return;
      }

      const zoom = mapRef.current?.getZoom() ?? 8;
      const center = calculateGeometryCenter(geometry) as [number, number];

      mapRef.current.flyTo({
        zoom: zoom,
        center: center,
        essential: true,
      });
    };

    const handleExportEntity = async (node: EntityNode) => {
      if (!node.data.entity.entity.id) {
        return;
      }
      notify.success(infoMessages.ENTITY_EXPORT_STARTED);
      await exportEntity({
        maxMediaItems: 1,
        type: 'kmz',
        entityID: node.data.entity.entity.id,
      })
        .then((response) => {
          downloadObject(response.data, 'AstraExport.kmz');
        })
        .catch(() => notify.error(errorMessages.ENTITY_EXPORT_ERROR));
    };

    /* *** Folder handlers *** */

    const getFolderCounters = (id: number, objectTemplateId?: number) => {
      dispatch(
        getMapEntityCountersThunk({
          parentEntityID: id,
          ...(objectTemplateId && {
            templateIDs: [objectTemplateId],
          }),
        })
      );
    };

    const onFolderOpen = (isOpen: boolean, node: EntityNode) => {
      const shouldGetEntityChildren = !!(
        node.data.info.level &&
        !((node.data.info.level + 1) % (MAP_ENTITY_HIERARCHY_NEST_LEVEL - 1)) &&
        node.data.state.storage === 'none'
      );

      // TODO: make proper query optimization
      const shouldGetEntityCounters = !isOpen;

      shouldGetEntityChildren &&
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityIDs: [Number(node.id)],
            search,
            mode: 'partial',
          })
        );

      shouldGetEntityCounters &&
        getFolderCounters(Number(node.id), objectTemplate?.template?.id);
    };

    const handleFolderSelect = async (e: unknown, node: EntityNode) => {
      const shouldGetEntityChildren = node.data.state.storage !== 'full';

      if (shouldGetEntityChildren) {
        dispatch(
          getMapEntityChildrenThunk({
            parentEntityIDs: [Number(node.id)],
            maxNestedEntityLevel: 9999,
            search,
            mode: 'full',
          })
        );
      } else {
        const selectedEntities = getEntityChildrenWithUpdatedState(
          entitiesMap,
          node.id,
          { active: true }
        );

        dispatch(
          mapEntitiesActions.setEntitiesMap({
            ...entitiesMap,
            ...selectedEntities,
          })
        );
      }
    };

    const handleFolderDeselect = (e: unknown, node: EntityNode) => {
      const deselectedEntities = getEntityChildrenWithUpdatedState(
        entitiesMap,
        node.id,
        { active: false }
      );

      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          ...deselectedEntities,
        })
      );
    };

    const handleFolderEditingChange = (editing: boolean, node: EntityNode) =>
      editing ? node.edit() : node.reset();

    const handleCreateFolder = (node: EntityNode) => {
      setProcessedNode(node);
      setLayerDetailsProps({
        onClose: handleLayerModalClose,
        parentId: Number(node.data.id),
      });
    };

    const handleEditFolderDetails = (node: EntityNode) => {
      setProcessedNode(node);
      setLayerDetailsProps({
        onClose: handleLayerModalClose,
        layer: node,
      });
    };

    const handleFolderClick = async (e: unknown, node: EntityNode) => {
      if (node.data.state.selected) {
        handleFolderDeselect(e, node);
      } else {
        handleFolderSelect(e, node);
      }
    };

    /* *** Object handlers *** */

    const handleObjectSelect = (e: unknown, node: EntityNode) => {
      const selectedEntity = entitiesMap[node.id];

      zoomToObject(node);
      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          [node.id]: {
            ...selectedEntity,
            state: { ...selectedEntity.state, active: true },
          },
        })
      );
    };

    const handleObjectDeselect = (e: unknown, node: EntityNode) => {
      const deselectedEntity = entitiesMap[node.id];

      dispatch(
        mapEntitiesActions.setEntitiesMap({
          ...entitiesMap,
          [node.id]: {
            ...deselectedEntity,
            state: { ...deselectedEntity.state, active: false },
          },
        })
      );

      dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));
      dispatch(modalsActions.removeModal(ENTITY_DETAILS_MODAL));
    };

    const handleObjectEditingEnable = (node: EntityNode) => node.edit();
    const handleObjectEditingReset = (node: EntityNode) => node.reset();

    const handleEditObjectDetails = (node: EntityNode) => {
      const mapEntity = entitiesMap[node.data.id];

      if (mapEntity) {
        dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));
        dispatch(
          modalsActions.addModal({
            id: ENTITY_DETAILS_MODAL,
            isOpen: true,
            props: mapEntity,
          })
        );
      }
    };

    const handleObjectClick = (e: unknown, node: EntityNode) =>
      node.data.state.selected
        ? handleObjectDeselect(e, node)
        : handleObjectSelect(e, node);

    const handleObjectDoubleClick = (e: unknown, node: EntityNode) => {
      handleObjectSelect(e, node);
      handleEditObjectDetails(node);
    };

    /* *** Node handlers *** */

    const onNodeOpen = (isOpen: boolean, node: EntityNode) => {
      if (!node.data.isFolder) {
        return;
      }

      onFolderOpen(isOpen, node);
    };

    const handleNodeClick = (e: unknown, node: EntityNode) =>
      node.data.isFolder
        ? handleFolderClick(e, node)
        : handleObjectClick(e, node);

    const handleNodeDoubleClick = (e: unknown, node: EntityNode) =>
      node.data.isFolder ? undefined : handleObjectDoubleClick(e, node);

    const handleNodeToggle = (node: EntityNode) => handleNodeClick(null, node);

    const handleNodeEditingChange = (editing: boolean, node: EntityNode) => {
      if (node.data.isFolder) {
        handleFolderEditingChange(editing, node);

        return;
      }

      node.isEditing && handleObjectEditingReset(node);
    };

    const handleNodeEdit = (value: string, node: EntityNode) => {
      const editedEntity = node.data.entity.entity;

      dispatch(upsertEntityThunk({ ...editedEntity, title: value }));
    };

    const handleNodeMove = (data: EntityNodeMoveData) => {
      const oldParentEntityId = Number(data.dragNodes[0].parent?.id) || 0;
      const newParentEntityId = Number(data.parentId) || 0;

      const relinkParams = {
        entityId: Number(data.dragNodes[0].id) || 0,
        oldParentEntityId: oldParentEntityId,
        newParentEntityId: newParentEntityId,
      };

      dispatch(relinkEntityThunk(relinkParams));
    };

    const handleNodeDelete = async () => {
      if (!processedNode) {
        return null;
      }

      const entityId = Number(processedNode.data.id);
      await dispatch(deleteEntityThunk(entityId));

      setDeleteModalOpen(false);
    };

    const handleDeleteModalOpen = (node: EntityNode) => {
      setProcessedNode(node);
      setDeleteModalOpen(true);
    };

    const handleChangeDefaultProject = (node: EntityNode) => {
      isDefaultProject(node)
        ? localStorage.removeItem('default_project')
        : localStorage.setItem('default_project', node.data.id);
    };

    const isDefaultProject = (node: EntityNode) => {
      return String(node.data.id) === localStorage.getItem('default_project');
    };

    const getFolderContextMenu = (node: EntityNode) => [
      {
        title: 'Экспорт в KMZ',
        onClick: () => handleExportEntity(node),
      },
      {
        title: `${isDefaultProject(node) ? 'Убрать' : 'Сделать'} по умолчанию`,
        onClick: () => handleChangeDefaultProject(node),
      },
      {
        title: 'Редактировать',
        onClick: () => handleEditFolderDetails(node),
      },
      {
        title: 'Создать подпроект',
        onClick: () => handleCreateFolder(node),
      },
      {
        title: 'Удалить',
        onClick: () => handleDeleteModalOpen(node),
      },
    ];

    const getObjectContextMenu = (node: EntityNode) => [
      {
        title: 'Экспорт в KMZ',
        onClick: () => handleExportEntity(node),
      },
      {
        title: 'Переименовать',
        onClick: () => handleObjectEditingEnable(node),
      },
      {
        title: 'Редактировать',
        onClick: () => handleEditObjectDetails(node),
      },
      {
        title: 'Удалить',
        onClick: () => handleDeleteModalOpen(node),
      },
    ];

    const handleNodeContextMenu = (
      node: EntityNode
    ): IContextMenuTooltipItem[] => {
      if (node.data.isFolder) {
        return getFolderContextMenu(node);
      }

      return getObjectContextMenu(node);
    };

    return (
      <>
        <div ref={ref} className="w-full h-full pl-4 overflow-auto">
          {hasData ? (
            <Tree<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState>
              data={data}
              height={height}
              indent={NODE_INDENT}
              disableEdit={false}
              disableDrag={false}
              disableDrop={getNodeDropDisabled}
              onMove={handleNodeMove}
              {...props}
            >
              {(nodeRendererProps) => (
                <Node<MapEntity, MapEntity, EntityNodeInfo, EntityNodeState>
                  renderArrow={renderNodeArrow}
                  renderIcon={renderNodeIcon}
                  renderName={renderNodeName}
                  renderCounter={(node) =>
                    renderNodeCounter(node, () => handleNodeToggle(node))
                  }
                  getContextMenu={handleNodeContextMenu}
                  onOpen={onNodeOpen}
                  onEditingChange={handleNodeEditingChange}
                  onEdit={handleNodeEdit}
                  onClick={handleNodeClick}
                  onDoubleClick={handleNodeDoubleClick}
                  className="w-full h-full pr-4 flex items-center"
                  {...nodeRendererProps}
                />
              )}
            </Tree>
          ) : (
            <span className="tpg-c1 text-tpg_base">
              {NOTHING_WAS_FOUND_MESSAGE}
            </span>
          )}
        </div>
        {isDeleteModalOpen && (
          <ConfirmModal
            title="Вы уверены, что хотите удалить?"
            description="Вы можете потерять ценные данные."
            onConfirm={handleNodeDelete}
            onClose={handleDeleteModalClose}
          />
        )}
      </>
    );
  }
);

EntitiesTree.displayName = 'EntitiesTree';
