import { Layer, Source } from 'react-map-gl';
import {
  IMAGERY_BOUNDARY_LAYER,
  IMAGERY_PREVIEW_FILL_LAYER,
  IMAGERY_SOURCE_PREFIX,
  LINE_LAYER,
} from 'constants/map';
import { ASTRA_COLORS } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { IImageryObject } from 'interfaces';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';

import {
  getProperOpacity,
  getSafeBeforeId,
  prepareFeatureCollection,
  preparePolygonFeature,
} from 'utils';
import { constructTilesetUrl } from 'utils/tileserver';

const getImageryPolygon = (
  item: IImageryObject,
  shouldDuplicateGeometry: boolean
) => {
  const minLon = Math.min(item.bounds[0], item.bounds[2]);
  const maxLon = Math.max(item.bounds[0], item.bounds[2]);
  const minLat = Math.min(item.bounds[1], item.bounds[3]);
  const maxLat = Math.max(item.bounds[1], item.bounds[3]);
  const bbox = [
    [minLon, maxLat],
    [maxLon, maxLat],
    [maxLon, minLat],
    [minLon, minLat],
    [minLon, maxLat],
  ];

  return preparePolygonFeature(item.id, [bbox], {
    id: item.id,
    relatedFeatureId: item.id,
    ...(shouldDuplicateGeometry && {
      geometry: {
        type: 'Polygon',
        coordinates: [bbox],
      },
    }),
  });
};

export const ImagerySource = () => {
  const { mapRef } = useMapRef();
  const imagery = useAppSelector(imagerySelector);

  const getImageryPreview = () => {
    const featureCollection = prepareFeatureCollection();
    const visibleImageryIds = new Set(
      imagery.visibleObjects.map((value) => value.id)
    );

    featureCollection.features = imagery.objects
      .filter((item) => !visibleImageryIds.has(item.id))
      .map((item) => getImageryPolygon(item, false));

    return featureCollection;
  };

  const getImageryBoundary = () => {
    const featureCollection = prepareFeatureCollection();

    featureCollection.features = imagery.visibleObjects.map((item) =>
      getImageryPolygon(item, true)
    );

    return featureCollection;
  };

  return (
    <>
      {imagery.showPreview && (
        <Source
          id="imagery-preview-source"
          type="geojson"
          data={getImageryPreview()}
          maxzoom={15}
        >
          <Layer
            id={IMAGERY_PREVIEW_FILL_LAYER}
            type="fill"
            source="imagery-preview-source"
            paint={{
              'fill-color': 'rgba(66, 130, 230, 0.40)',
            }}
          />
          <Layer
            id="imagery-preview-line-layer"
            type="line"
            source="imagery-preview-source"
            paint={{
              'line-color': ASTRA_COLORS.BRIGHT_PRODUCT,
              'line-width': 3,
            }}
          />
        </Source>
      )}
      <Source
        id="imagery-boundary-source"
        type="geojson"
        data={getImageryBoundary()}
        maxzoom={15}
      >
        <Layer
          id={IMAGERY_BOUNDARY_LAYER}
          type="fill"
          source="imagery-preview-source"
          paint={{
            'fill-color': 'transparent',
          }}
        />
      </Source>
      {imagery.visibleObjects.map((item) => (
        <Source
          id={`${IMAGERY_SOURCE_PREFIX}-${item.id}`}
          key={`${IMAGERY_SOURCE_PREFIX}-${item.id}`}
          type="raster"
          tileSize={128}
          minzoom={item.minzoom}
          maxzoom={item.maxzoom}
          bounds={item.bounds}
          tiles={[constructTilesetUrl(item.tileset_url)]}
        >
          <Layer
            id={`imagery-layer-${item.id}`}
            type="raster"
            source={`${IMAGERY_SOURCE_PREFIX}-${item.id}`}
            beforeId={getSafeBeforeId(mapRef.current, LINE_LAYER)}
            paint={{ 'raster-opacity': getProperOpacity(item) }}
          />
        </Source>
      ))}
    </>
  );
};
