import { useNavigate } from 'react-router-dom';
import { mapRoutes } from 'constants/routes';
import { useAppDispatch } from 'hooks';
import { mapActions } from 'store';

import { ContextPopup, ContextPopupOption } from './ContextPopup';

interface DefaultContextMenuProps {
  longitude: number;
  latitude: number;
  options: ContextPopupOption[];
  onClose: () => void;
}

export const DefaultContextMenu = ({
  longitude,
  latitude,
  options: optionsProp,
  onClose,
}: DefaultContextMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToGeospoof = () => navigate(mapRoutes.SEARCH);

  const handleGeospoofClick = () => {
    navigateToGeospoof();
    dispatch(mapActions.setExternalGeospoofRequest([longitude, latitude]));
  };

  const handleGeospoofHistoryClick = () => {
    navigateToGeospoof();
    dispatch(mapActions.setGeospoofTab('history'));
  };

  const options = [
    ...optionsProp,
    { title: 'Геоспуф', onClick: handleGeospoofClick },
    { title: 'История геоспуфа', onClick: handleGeospoofHistoryClick },
  ];

  return (
    <ContextPopup
      options={options}
      longitude={longitude}
      latitude={latitude}
      keyboard
      onClose={onClose}
    />
  );
};
