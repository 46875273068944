import { FC } from 'react';
import { MapboxGeoJSONFeature } from 'react-map-gl';
import { FeatureTypes } from 'constants/map';
import { Geometry, Position } from 'geojson';
import { useAppSelector } from 'hooks';
import { coordsModeSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import {
  BoundaryGrid,
  BoundaryGridCellGeoJSON,
  ContextMenu as IContextMenu,
  ImageryBoundaryGeoJSON,
} from 'types';

import { BoundaryGridContextMenu } from 'components/Map/ContextMenu/BoundaryGridContextMenu';
import { DefaultContextMenu } from 'components/Map/ContextMenu/DefaultContextMenu';
import { DrawnFeatureContextMenu } from 'components/Map/ContextMenu/DrawnFeatureContextMenu';
import { ImageryContextMenu } from 'components/Map/ContextMenu/ImageryContextMenu';
import { getVisibleCoordMode } from 'utils';

import { copyCoordinates } from './utils';

interface ContextMenuProps {
  contextMenu: IContextMenu;
  onClose: () => void;
  handleAddEntity: (geometry: Geometry) => void;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  contextMenu,
  onClose,
  handleAddEntity,
}) => {
  const coordsMode = useAppSelector(coordsModeSelector);
  const { latitude, longitude } = contextMenu;

  const handleAddPoint = (position: Position) =>
    handleAddEntity({
      type: FeatureTypes.POINT,
      coordinates: position,
    });

  const commonOptions = [
    {
      title: `Скопировать координату в ${getVisibleCoordMode(coordsMode)}`,
      onClick: () => copyCoordinates(coordsMode, latitude, longitude),
    },
    {
      title: 'Создать объект',
      onClick: () => handleAddPoint([longitude, latitude]),
    },
  ];

  const isDefaultContextMenuOpen = contextMenu?.type === 'default';
  const isDrawnFeatureContextMenuOpen = contextMenu?.type === 'drawn_feature';
  const isBoundaryGridContextMenuOpen = contextMenu?.type === 'boundary_grid';
  const isImageryContextMenuOpen = contextMenu?.type === 'imagery';

  return (
    <>
      {isDefaultContextMenuOpen && (
        <DefaultContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          options={commonOptions}
          onClose={onClose}
        />
      )}
      {isDrawnFeatureContextMenuOpen && (
        <DrawnFeatureContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          feature={contextMenu.props.feature as MapboxGeoJSONFeature}
          onClose={onClose}
          onCreate={handleAddEntity}
        />
      )}
      {isImageryContextMenuOpen && (
        <ImageryContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          options={commonOptions}
          imageryBoundary={
            contextMenu.props.imageryBoundary as ImageryBoundaryGeoJSON
          }
          onClose={onClose}
        />
      )}
      {isBoundaryGridContextMenuOpen && (
        <BoundaryGridContextMenu
          longitude={contextMenu.longitude}
          latitude={contextMenu.latitude}
          options={commonOptions}
          boundaryGrid={contextMenu.props.boundaryGrid as BoundaryGrid}
          boundaryGridCell={
            contextMenu.props.boundaryGridCell as BoundaryGridCellGeoJSON
          }
          onClose={onClose}
        />
      )}
    </>
  );
};
