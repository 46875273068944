import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteImagery } from 'api/map';
import cn from 'classnames';
import { DATE_FORMAT } from 'constants/map';
import { IMAGERY_UPLOAD_MODAL } from 'constants/modals';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import dayjs from 'dayjs';
import { useAppDispatch, usePermission } from 'hooks';
import { ReactComponent as ImportIcon } from 'images/icons/import.svg';
import { ReactComponent as Up } from 'images/newIcons/up.svg';
import { IImageryFilter, IImageryObject } from 'interfaces';
import { boundaryGridActions } from 'store/slices/mapV2/mapReducer/toolsReducer/boundaryGridSlice';
import { imageryActions } from 'store/slices/mapV2/tabsReducer/imagerySlice';
import { getImageryThunk } from 'store/slices/mapV2/tabsReducer/imagerySlice/actions';
import { imagerySelector } from 'store/slices/mapV2/tabsReducer/selectors';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { TDateRange } from 'types';
import { useDebouncedCallback } from 'use-debounce';

import TagContainer, { TDrawableTag } from 'components/Monitoring/TagContainer';
import { Switch, TextInput } from 'components/ui';
import ClosableTooltip from 'components/ui/ClosableTooltip';
import { DatePickerDropdown } from 'components/ui/DatePickerDropdown/DatePickerDropdown';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import SidebarWindowWithTabs from 'components/ui/SidebarWindowWithTabs';
import { getTagDate, notify } from 'utils';

import ImageryItem from './ImageryItem';

import './style.scss';

const MapImagery = () => {
  const dispatch = useAppDispatch();
  const imagery = useSelector(imagerySelector);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<TDateRange>([null, null]);
  const [processedImagery, setProcessedImagery] = useState<IImageryObject>();
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [tags, setTags] = useState<TDrawableTag>();
  const [showSettings, setShowSettings] = useState(false);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [startDate, endDate] = dateRange;
  const isAccessDeleteImagery = usePermission('delete-imagery');

  const dateOrUndef = (date: Date | null) =>
    date ? dayjs(date).format(DATE_FORMAT) : undefined;

  const debouncedSearch = useDebouncedCallback(
    (searchPayload: IImageryFilter) => dispatch(getImageryThunk(searchPayload)),
    150
  );

  const getSearchPayload = (): IImageryFilter => {
    const includeDate = startDate && endDate;
    return {
      name: searchValue || undefined,
      fdate: includeDate && dateOrUndef(startDate),
      tdate: includeDate && dateOrUndef(endDate),
    } as IImageryFilter;
  };

  const searchWithFilters = () => {
    debouncedSearch(getSearchPayload());
  };

  useEffect(() => {
    searchWithFilters();
  }, [searchValue, showOnlyActive]);

  useEffect(() => {
    if (Boolean(startDate) == Boolean(endDate)) {
      searchWithFilters();
      if (startDate && endDate) {
        const dateRangeString = getTagDate(
          `${dayjs(startDate).format('DD.MM.YY')}-${dayjs(endDate).format(
            'DD.MM.YY'
          )}`
        );
        setTags({
          label: dateRangeString,
          value: dateRangeString,
          onRemove: () => setDateRange([null, null]),
          title: 'Дата',
        });
      } else {
        setTags(undefined);
      }
    }
  }, [dateRange, showOnlyActive]);

  const checkedImages = useMemo(
    () => new Set(imagery.visibleObjects.map((value) => value.id)),
    [imagery.visibleObjects, showOnlyActive]
  );

  const handleCalendarClose = () => {
    if (startDate && !endDate) {
      setDateRange([startDate, startDate]);
    }
  };

  const handleOpenUploadModal = useCallback(() => {
    dispatch(
      modalsActions.addModal({ id: IMAGERY_UPLOAD_MODAL, isOpen: true })
    );
  }, []);

  const handleDelete = async () => {
    if (processedImagery) {
      await deleteImagery(processedImagery.id).catch(() =>
        notify.error('Не удалось удалить снимок')
      );
      await dispatch(getImageryThunk(getSearchPayload()));
    }
  };

  const getTooltipOverlay = (imagery: IImageryObject) => (
    <div className={'astra-dropdown__actions'}>
      <button
        className="tpg-body-one astra-dropdown__actions__danger"
        onClick={() => {
          setDeleteModal(true);
          setProcessedImagery(imagery);
        }}
      >
        Удалить снимок
      </button>
    </div>
  );

  const removeAllImageryObjects = () => {
    dispatch(imageryActions.setVisibleObjects([]));
    dispatch(
      boundaryGridActions.updateBoundaryGrids({
        relatedFeatureIds: Array.from(checkedImages),
        properties: { visible: false },
      })
    );
  };

  const wrapInTooltip = (
    imagery: IImageryObject,
    index: number,
    element: ReactElement
  ) => (
    <ClosableTooltip
      overlay={getTooltipOverlay(imagery)}
      key={`imagery-body__items__row-${index}`}
    >
      {element}
    </ClosableTooltip>
  );

  const imagerySortFunction = (a: IImageryObject, b: IImageryObject) => {
    const firstDate = dayjs(a.created_at);
    const secondDate = dayjs(b.created_at);
    if (firstDate.isSame(secondDate)) {
      return a.name < b.name ? -1 : 1;
    } else if (firstDate.isBefore(secondDate)) {
      return 1;
    } else {
      return -1;
    }
  };

  const getImageryRow = (value: IImageryObject, index: number) => {
    return (
      <ImageryItem key={`imagery-body__items__row-${index}`} value={value} />
    );
  };

  const getDeletableImageryRow = (value: IImageryObject, index: number) =>
    wrapInTooltip(value, index, getImageryRow(value, index));

  const renderedImagery = useMemo(() => {
    const imageryToShow = showOnlyActive
      ? imagery.visibleObjects
      : imagery.objects;
    return imageryToShow
      .slice()
      .sort(imagerySortFunction)
      .map((value) =>
        isAccessDeleteImagery
          ? getDeletableImageryRow(value, value.id)
          : getImageryRow(value, value.id)
      );
  }, [imagery, checkedImages]);

  const toggleImageryPreview = () =>
    dispatch(imageryActions.setShowPreview(!imagery.showPreview));

  const imageryTab = (
    <div className="imagery-body flex flex-col">
      <div className="flex flex-row justify-between p-4">
        <div
          className="flex flex-row gap-1 hover:cursor-pointer"
          onClick={() => setShowSettings(!showSettings)}
        >
          <Up
            className={cn(
              'h-4 w-4 mt-[2px]',
              showSettings
                ? 'rotate-180 [&>path]:fill-bright_product'
                : 'rotate-90 [&>path]:fill-tpg_base'
            )}
          />
          <div
            className={cn(
              'tpg-c2',
              showSettings ? 'text-bright_product' : 'text-tpg_base'
            )}
          >
            Настройки
          </div>
        </div>
        <div className="flex flex-row tpg-c2 gap-1">
          <div className="text-tpg_title">Сортировка</div>
          <div className=" text-tpg_light">По дате загрузки</div>
        </div>
      </div>
      {showSettings && (
        <div className="flex items-center justify-between p-4 pt-0">
          <div className="tpg-c2">Показывать только активные</div>
          <Switch
            checked={showOnlyActive}
            onChange={() => setShowOnlyActive(!showOnlyActive)}
          />
        </div>
      )}
      <div className="imagery-body__filter pb-4 px-4">
        <div className="imagery-body__filter__item">
          <TextInput
            placeholder="Начните поиск"
            searchIcon
            className="search-container"
            value={searchValue}
            onChange={setSearchValue}
          >
            <DatePickerDropdown
              className="mt-5 top-0 right-0"
              onValueChange={setDateRange}
            />
          </TextInput>
        </div>
      </div>
      {tags && (
        <div className="px-4 pb-4 ">
          <TagContainer
            tags={[tags]}
            className="!m-0"
            tagClassName="bg-ultrablack"
          />
        </div>
      )}
      <div className={'imagery-body__items px-4 gap-3 flex flex-col grow'}>
        {renderedImagery}
        {(imagery.objects.length === 0 ||
          (showOnlyActive && imagery.visibleObjects.length === 0)) && (
          <div className="text-tpg_base tpg-c1">
            {NOTHING_WAS_FOUND_MESSAGE}
          </div>
        )}
      </div>
      <div className="imagery-body__footer flex justify-between items-center bottom-0 tpg-c2">
        <div className="flex flex-row gap-3">
          <div className="flex cursor-pointer" onClick={handleOpenUploadModal}>
            <ImportIcon />
            <div className="pl-1 text-tpg_base">Импорт</div>
          </div>
          {imagery.visibleObjects.length ? (
            <div
              className="tpg-c2 text-bright_product cursor-pointer"
              onClick={removeAllImageryObjects}
            >
              Сбросить {imagery.visibleObjects.length}
            </div>
          ) : null}
        </div>
        <div>
          <div className="flex items-center">
            <div className="pr-2">Превью на карте</div>
            <Switch
              checked={imagery.showPreview}
              onChange={toggleImageryPreview}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SidebarWindowWithTabs
        tabs={[imageryTab]}
        tabOptions={['Снимки']}
        hideOptions={true}
      />
      {isDeleteModal && (
        <ConfirmModal
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default MapImagery;
